// Farm Report Routes...
export const farmMachineryReport = '/farmMachinery/report/frms'
// circular list api
export const circularListApi = '/farmMachinery/allocation/circular-list'
// machine type list api
export const machineTypeListApi = '/farmMachinery/allocation/machine-type-list'
// machine type list api
export const allocationReportApi = '/farmMachinery/report/allocation-report'
export const supplierWiseMachineAssignReport = '/farmMachinery/report/supplier-machine-assign'
// distribution report
export const distributionReportApi = '/farmMachinery/report/distributed-report'
export const distributedSupplierListApi = '/farmMachinery/config/supplier-info/distributed-supplier-list'
