<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('farm_report.allocation_report_title') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                        <!-- fiscal year id -->
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.fiscal_year_id"
                                        id="fiscal_year_id"
                                        :options="fiscalYearList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- season -->
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="season_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('farm_config.season')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="search.season_id"
                                        id="season_id"
                                        :options="seasonList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- circular -->
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_id"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                        {{$t('farm_config.circular')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        :disabled="circularLoading ? true : false"
                                        v-model="search.circular_id"
                                        id="circular_id"
                                        :options="circularList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                      <template v-slot:first>
                                            <b-form-select-option :value="0">{{circularLoading ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      <b-col cols="12">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('farm_report.allocation_report_title') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport" v-if="pdfButton">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="2">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{currentLocale === 'bn' ? 'সমন্বিত ব্যাবস্থাপনার মাধ্যমে কৃষি যান্ত্রিকীকরণ প্রকল্পের আওতায়' : 'Under Agricultural Mechanization Scheme through Integrated Management'}} <br>
                                        {{ currentLocale === 'bn' ? getFiscalYearName(search.fiscal_year_id) + ' অর্থ বছরে ' + getSeasonName(search.season_id)  + ' ফসলের উপজেলা ওয়ারি যন্ত্র ভিক্তিক বরাদ্দ বিভাজন' : 'Upazila Wari Machine Based Allocation Division of ' + getSeasonName(search.season_id)  + ' Crops in ' + getFiscalYearName(search.fiscal_year_id) + ' Fiscal Year' }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                <div class="text-black">
                                <b-row>
                                  <b-col md="4"><strong>{{ $t('globalTrans.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? getFiscalYearName(search.fiscal_year_id) : $t('globalTrans.all')  }}</b-col>
                                  <b-col md="4"><strong>{{ $t('farm_allocation.season') }}: </strong>{{ search.season_id  ?  getSeasonName(search.season_id) : $t('globalTrans.all') }}</b-col>
                                  <b-col md="4"><strong>{{ $t('farm_allocation.circular') }}: </strong>{{ search.circular_id ? getCircularName(search.circular_id) : $t('globalTrans.all')  }}</b-col>
                                </b-row>
                              </div>
                                <template>
                                  <b-table-simple class="tg mt-3" bordered hover row-hovered small caption-top responsive show-empty>
                                    <b-thead>
                                        <tr>
                                            <b-th rowspan="2" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th rowspan="2" class="text-center">{{ $t('globalTrans.district') }}</b-th>
                                            <b-th rowspan="2" class="text-center">{{ $t('globalTrans.upazila') }}</b-th>
                                            <template v-if="Object.keys(machineTypeList).length > 0">
                                              <b-th :colspan="machineType.instruments.length" class="text-center" v-for="(machineType, machineTypeIndex) in machineTypeList" :key="'mechine_type-' + machineTypeIndex">
                                                {{ currentLocale === 'en' ? machineType.machine_type : machineType.machine_type_bn }}
                                              </b-th>
                                            </template>
                                        </tr>
                                        <tr class="text-center" v-if="Object.keys(machineTypeList).length > 0">
                                          <template v-for="(machineType, machineTypeIndex) in machineTypeList">
                                            <b-th v-for="(machine, machineIndex) in machineType.instruments" :key="'mechine-name-' + machineTypeIndex + machineIndex" class="text-center">
                                              {{ currentLocale === 'en' ? machine.name : machine.name_bn }}
                                            </b-th>
                                          </template>
                                        </tr>
                                    </b-thead>
                                    <b-tbody>
                                        <template v-if="Object.keys(datas).length > 0">
                                          <slot  v-for="(item, index) in datas">
                                            <b-tr v-for="(districtItem, districtIndex) in item.details" :key="'district-item-' + districtIndex">
                                                <b-td  class="text-center">{{ $n(districtIndex + 1) }}</b-td>
                                                <b-td v-if="districtIndex === 0" class="text-center" :rowspan="item.details.length">{{ getDistrictName(districtItem.district_id) }}</b-td>
                                                <b-td class="text-center">{{ getUpazilaName(districtItem.upazilla_id) }}</b-td>
                                                <b-td class="text-center" v-for="(upazilaItem, upazilaIndex) in districtItem.machine_type_info" :key="'upazila-item-' + upazilaIndex">
                                                  {{upazilaItem.quantity ? $n(upazilaItem.quantity) : ''}}
                                                </b-td>
                                            </b-tr>
                                            <tr :key="index">
                                              <b-td colspan="3"  class="text-right">{{ $t('farm_report.district_total') }}</b-td>
                                                <template v-for="(machineType, machineTypeIndex) in machineTypeList">
                                                  <b-th v-for="(machine, machineIndex) in machineType.instruments" :key="'mechine-name-' + machineTypeIndex + machineIndex" class="text-center">
                                                    {{$n(getTotalMechine(item.allocation_quantities, machine.id))}}
                                                  </b-th>
                                              </template>
                                            </tr>
                                          </slot>
                                        </template>
                                        <template v-else>
                                            <b-tr>
                                                <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                        </template>
                                    </b-tbody>
                                  </b-table-simple>
                                </template>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { core } from '@/config/pluginInit'
  import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
  import { allocationReportApi, circularListApi, machineTypeListApi } from '../../api/routes'
  import flatpickr from 'flatpickr'
  import ListReportHead from '@/components/custom/ListReportHead.vue'
  import ExportPdf from './export-pdf_details'
  import excel from 'vue-excel-export'
  import Vue from 'vue'
  Vue.use(excel)

  export default {
      props: ['id'],
      components: {
          ListReportHead
      },
      created () {
        this.getCircularList()
      },
      data () {
          return {
            search: {
              circular_id: 0,
              season_id: 0,
              fiscal_year_id: 0
            },
            machineTypeList: [],
            loadMechineType: false,
            circularList: [],
            circularLoading: false,
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            datas: [],
            loading: false,
            showData: false,
            pdfButton: false
          }
      },
      mounted () {
          core.index()
          flatpickr('.datepicker', {})
      },
      computed: {
        currentLocale () {
          return this.$i18n.locale
        },
        seasonList: function () {
          return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
          ]
        },
        fiscalYearList () {
          return this.$store.state.commonObj.fiscalYearList
        }
      },
      watch: {
        'search.fiscal_year_id': function (newVal, oldVal) {
          if (newVal) {
            this.search.circular_id = 0
            this.getCircularList()
          }
        },
        'search.season_id': function (newVal, oldVal) {
          if (newVal) {
            this.search.circular_id = 0
            this.getCircularList()
          }
        },
        'search.circular_id': function (newVal, oldVal) {
          if (newVal) {
            this.getMachineTypeList()
          }
        },
        currentLocale: function (newVal, oldVal) {
          this.getCircularList()
          if (this.search.circular_id) {
            this.getMachineTypeList()
          }
        }
      },
      methods: {
        async getMachineTypeList () {
            const params = {
              circular_id: this.search.circular_id
            }
            this.loadMechineType = true
            let result = null
            result = await RestApi.getData(incentiveGrantServiceBaseUrl, machineTypeListApi, params)
            if (result.success) {
              this.machineTypeList = result.data
            } else {
              this.machineTypeList = []
            }
            this.loadMechineType = false
        },
        async getCircularList () {
          this.circularLoading = true
          const params = {
            fiscal_year_id: this.search.fiscal_year_id,
            season_id: this.search.season_id,
            lang: this.currentLocale
          }
          let result = null
          result = await RestApi.getData(incentiveGrantServiceBaseUrl, circularListApi, params)
          if (result.success) {
              this.circularList = result.data
          } else {
              this.circularList = []
          }
          this.circularLoading = false
        },
        getColumnName (list, groupId) {
            const obj = list.find(item => item.value === groupId)
            if (typeof obj !== 'undefined') {
                if (this.$i18n.locale === 'bn') {
                    return obj.text_bn
                } else {
                    return obj.text_en
                }
            } else {
                return ''
            }
        },
        pdfExport () {
            const heading = this.currentLocale === 'bn' ? 'সমন্বিত ব্যাবস্থাপনার মাধ্যমে কৃষি যান্ত্রিকীকরণ প্রকল্পের আওতায়' : 'Under Agricultural Mechanization Scheme through Integrated Management'
            const headingTwo = this.currentLocale === 'bn' ? this.getFiscalYearName(this.search.fiscal_year_id) + ' অর্থ বছরে ' + this.getSeasonName(this.search.season_id) + ' ফসলের উপজেলা ওয়ারি যন্ত্র ভিক্তিক বরাদ্দ বিভাজন' : 'Upazila Wari Machine Based Allocation Division of ' + this.getSeasonName(this.search.season_id) + ' Crops in ' + this.getFiscalYearName(this.search.fiscal_year_id) + ' Fiscal Year'
            const reportTitle = this.$t('farm_report.allocation_report_title')
            ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this.datas, this, this.search, this.machineTypeList, this.getFiscalYearName, this.getSeasonName, this.getCircularName, heading, headingTwo, this.getDistrictName, this.getUpazilaName, this.getTotalMechine)
        },
        async searchData () {
          var check = await this.$refs.form.validate()
          if (check) {
          this.loading = true
          this.showData = true
          const params = Object.assign({}, this.search)
          await RestApi.getData(incentiveGrantServiceBaseUrl, allocationReportApi, params).then(response => {
            if (response.success) {
              this.pdfButton = true
              this.datas = response.data
            } else {
              this.datas = []
            }
          })
          this.loading = false
          }
        },
        getStatus (status) {
          if (status === 2) {
              return this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved'
          } else {
            return this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যান' : 'Reject'
          }
        },
        getFiscalYearName (id) {
          const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getSeasonName (id) {
          const obj = this.seasonList.find(item => item.value === id)
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getCircularName (id) {
          const obj = this.$store.state.incentiveGrant.commonObj.circularList.find(item => item.value === id)
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getUpazilaName (id) {
          const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === id)
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getDistrictName (id) {
          const obj = this.$store.state.commonObj.districtList.find(item => item.value === id)
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getTotalMechine (districtAllocations, instrumentId) {
          let total = 0
          districtAllocations.forEach(element => {
            if (element.instrument_id === instrumentId) {
              total += element.quantity
            }
          })
          return total
        }
      }
  }
  </script>
