import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search, machineTypeList, getFiscalYearName, getSeasonName, getCircularName, heading, headingTwo, getDistrictName, getUpazilaName, getTotalMechine) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        pdfContent.push({ text: heading, style: 'header4', alignment: 'center' })
        pdfContent.push({ text: headingTwo, style: 'header4', alignment: 'center' })
        const allRowsHead = [
          [
            { text: vm.$t('globalTrans.fiscal_year') + ' : ' + (search.fiscal_year_id ? getFiscalYearName(search.fiscal_year_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('farm_allocation.season') + ' : ' + (search.season_id ? getSeasonName(search.season_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('farm_allocation.circular') + ' : ' + (search.circular_id ? getCircularName(search.circular_id) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.district'), rowSpan: 2, style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.upazila'), rowSpan: 2, style: 'th', alignment: 'center', bold: true }
          ],
          [
            { },
            { },
            { }
          ]
        ]
        machineTypeList.forEach(item => {
          const machineTypeLabel = i18n.locale === 'en' ? item.machine_type : item.machine_type_bn
          allRows[0].push({ text: machineTypeLabel, style: 'tableHeader', alignment: 'center', colSpan: item.instruments.length })
          let i = 1
          while (i < item.instruments.length) {
            allRows[0].push({})
            i++
          }
        })
        // allRows[0].push({})

        machineTypeList.forEach(item1 => {
          item1.instruments.forEach(instrumentItem => {
            const instrumentTypeLabel = i18n.locale === 'en' ? instrumentItem.name : instrumentItem.name_bn
            allRows[1].push({ text: instrumentTypeLabel, style: 'tableHeader', alignment: 'center' })
          })
        })
          if (data.length > 0) {
            data.forEach((item, optionIndex) => {
              item.details.forEach((districtItem, districtIndex) => {
                const NewLoop = []
                NewLoop.push({ text: vm.$n(districtIndex + 1), style: 'td', alignment: 'center' })
                if (districtIndex === 0) {
                  NewLoop.push({ text: getDistrictName(districtItem.district_id), style: 'td', rowSpan: item.details.length, alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                NewLoop.push({ text: getUpazilaName(districtItem.upazilla_id), alignment: 'center', style: 'td' })
                districtItem.machine_type_info.forEach((upazilaItem, upazilaIndex) => {
                  NewLoop.push({ text: upazilaItem.quantity ? vm.$n(upazilaItem.quantity, { useGrouping: false }) : '', alignment: 'center', style: 'td' })
                })
                allRows.push(NewLoop)
              })
              const totRow = [
                { text: vm.$t('farm_report.district_total'), colSpan: 3, style: 'th', alignment: 'center', bold: true },
                {},
                {}
              ]
              machineTypeList.forEach((machineType, machineTypeIndex) => {
                machineType.instruments.forEach((machine, machineIndex) => {
                  totRow.push({ text: vm.$n(getTotalMechine(item.allocation_quantities, machine.id, { useGrouping: false })), alignment: 'center', style: 'td' })
                })
              })
              allRows.push(totRow)
            })
            allRows.push(
            )
           } else {
              allRows.push(
                [
                  { text: vm.$t('globalTrans.noDataFound'), colSpan: 3, style: 'td', alignment: 'center' },
                  {},
                  {}
                ]
            )
          }
          pdfContent.push({
            table: {
              headerRows: 2,
              widths: '*',
              body: allRows
            }
          })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          header4: {
            fontSize: 14,
            margin: [0, 0, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
